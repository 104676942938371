.discount {
  .custom-control-label:before {
    background-color: getColor("white");
    border-radius: 0.5rem;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    border-color: getColor("success");
    background-color: getColor("success");
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
}

.discount-tabs {
  .check-inactive-codes {
    label {
      line-height: 24px;
    }
  }
  .custom-control-label:before {
    background-color: getColor("white");
    border-radius: 0.5rem;
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: getColor("success");
    border-color: getColor("success");
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
  label {
    color: getColor("grey-light-2");
    font-weight: 600;
  }
  .dropdown {
    .dropdown-menu {
      right: 0;
      left: inherit !important;
      border-radius: 0.3rem !important;
      box-shadow: 0px 2px 10px #ededed;
      border: none;
      min-width: 6rem;
      .dropdown-item {
        color: getColor("grey-light-2");
        font-size: 0.7rem;
        border-bottom: 1px solid #f4f4f4;
        line-height: 22px;
        padding: 3px 8px;
        &:last-child {
          border: none;
        }
        &:hover {
          background-color: getColor("grey");
        }
      }
    }
    button {
      color: getColor("primary") !important;
      border: none;
      background: #fff !important;
      box-shadow: 0px 2px 10px #ededed !important;
      border-radius: 0 4px 4px 0 !important;
      padding: 0.375rem 20px;
      color: getColor("primary");
      &::before {
        position: absolute;
        width: 1px;
        height: 18px;
        left: 0;
        top: 11px;
        content: "";
        background: #a9b0c7;
      }
      &::after {
        position: relative;
        width: 9px;
        height: 5px;
        border: none;
        right: -10px;
        background: #fff url(../assets/icons/select_arrow.png) no-repeat !important;
      }
    }
  }
  .border-dashed {
    border-left-style: dashed !important;
  }
  .coupon-code {
    min-width: 270px;
    text-align: center;
    .icon-check {
      text-align: center;
      margin-bottom: 20px;
      @include marginX(auto);
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background: getColor("success");
      display: inline-flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    h6 {
      font-size: getFontSize("md");
      font-weight: 600;
      color: getColor("grey-light-2");
      margin-bottom: 20px;
    }
    .code-box {
      border: 1px dashed #c6b7b7;
      min-height: 78px;
      position: relative;
      align-items: center;
      display: inline-flex;
      width: 100%;
      justify-content: center;
      font-size: getFontSize("md");
      color: getColor("primary");
      font-weight: 600;
      letter-spacing: 10px;
      word-wrap: break-word;
      max-width: 20rem;
      outline: 0px;
      text-align: center;
      &::after {
        position: absolute;
        left: -9px;
        top: 50%;
        transform: translateY(-50%);
        width: 17px;
        height: 17px;
        background: url(../assets/icons/scissors_down.png) no-repeat;
        content: "";
      }
      &::before {
        position: absolute;
        right: -9px;
        top: 50%;
        transform: translateY(-50%);
        width: 17px;
        height: 17px;
        background: url(../assets/icons/scissors_up.png) no-repeat;
        content: "";
      }
    }
  }
}

.code-switch {
  border: 0px;
  box-shadow: 0px 2px 10px #ededed;
  .form-label {
    color: getColor("primary");
    letter-spacing: 0.1rem;
  }

  strong {
    font-size: 1.2rem;
  }
}

.edit-coupon-modal {
  .modal-content {
    margin-top: 80px;
  }
  .modal-body {
    height: calc(100vh - 350px);
    overflow: auto;
  }
}
