.invoice {
  .invoice-header {
    background-color: map-get($theme-colors, "primary");
    img {
      width: 80px;
    }
    h5 {
      color: #fff;
      text-align: center;
    }
    hr {
      border: none;
      border-bottom: 2px solid #fff;
      width: 60%;
      margin: 0.5rem auto;
    }
    p {
      text-align: center;
      color: #fff;
      font-size: 0.8rem;
    }
  }
  .invoice-body {
    .invoice-top-label {
      color: #fff;
      margin-bottom: 0.5rem;
      display: inline-block;
      border-bottom: 2px solid map-get($theme-colors, "primary");
      width: 100%;
      span {
        background-color: map-get($theme-colors, "primary");
        padding: 0.2rem 1rem;
      }
    }
  }
  .invoice-details {
    color: #3b4773;
    .invoice-account {
      max-width: 754px;
      display: inline-block;
      width: 100%;
    }
  }
  .invoice-amount {
    border-top: 1px solid #cccccc;
    margin-top: 2rem;
    text-align: right;
    h6 {
      color: #cccccc;
      font-size: 0.8rem;
    }
    h4 {
      color: map-get($theme-colors, "primary");
    }
    h5 {
      color: #cccccc;
    }
    h2 {
      color: map-get($theme-colors, "primary");
    }
  }
  .invoice-payment-method {
    padding: 1rem;
    border: 1px dashed map-get($theme-colors, "primary");
    display: flex;
    justify-content: space-between;
  }

  .footer-line {
    border: none;
    border-bottom: 2px solid map-get($theme-colors, "primary");
    width: 80%;
    margin: 2rem auto 2rem;
  }

  .payment-message {
    color: #c0cfff;
    text-align: center;
  }
  .rotate-image {
    transform: rotate(-20deg);
  }
}

.paid {
  color: map-get($theme-colors, "success") !important;
  border: 2px dashed map-get($theme-colors, "success");
  padding: 0.5rem 1.5rem;
}
.unpaid {
  color: map-get($theme-colors, "danger") !important;
  border: 2px dashed map-get($theme-colors, "danger");
  padding: 0.5rem 1.5rem;
}
