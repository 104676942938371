.custom-tabs {
  .nav-link {
    border-radius: 0;
    font-size: 0.8rem;
  }
}

hr {
  border: 0.1rem solid getColor("primary");
  background-color: getColor("primary");
  border-radius: 1px;
  width: 2rem;
  @include marginX(0rem);
  margin-top: 0rem;
  &.secondary {
    border: 2px solid getColor("grey-light-4");
    background-color: getColor("grey-light-4");
  }
}

.btn-collapse {
  border: 0px;
  background-color: transparent;
  padding: 0.2rem;

  &:focus {
    outline: none;
  }
}

.pointer {
  cursor: pointer;
}

table.table {
  tr {
    .btn-hidden {
      visibility: hidden;
    }
    &:hover {
      .btn-hidden {
        visibility: visible;
      }
    }
  }
  th {
    border-top: 0px;
    color: #a9b0c7;
  }
  td {
    border: 0px;
    color: getColor("grey-light-2");
    outline: 0px;

    .media img {
      width: 70px;
      height: 70px;
      border-radius: 0.4rem;
      object-fit: cover;
    }
  }
  h6 {
    font-family: $font-family-muli;
  }
  &.table-hover {
    tbody tr {
      transition: all 0.5s;
      border-bottom: 2px solid getColor("grey-light-4");
      &:hover {
        background-color: getColor("white");
        @include shadow(0.5);
      }
    }
  }
}

.rounded-xl {
  border-radius: 1rem !important;
}

.custom-select {
  background: getColor("white") url(../assets/icons/select_arrow.png) no-repeat right 15px top 18px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.modal-content {
  border-radius: 1.5rem;
  .modal-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
  }
  .btn-close {
    color: white;
    background-color: transparent;
    border: 0px;
    margin-right: 0.5rem;
    padding: 0px;
    align-self: center;
    img {
      width: 15px;
      display: block;
    }
  }
}

.img-fit-cover {
  object-fit: cover;
}

.img-fit-contain {
  object-fit: contain;
}

.switch-success {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: getColor("success");
    background-color: getColor("success");
  }
  .custom-control-input:not(:checked) ~ .custom-control-label::before {
    border-color: #adb5bd;
    background-color: #adb5bd;
  }
  .custom-control-input:not(:checked) ~ .custom-control-label::after {
    background-color: white;
  }
  .custom-control-label {
    &::before,
    &::after {
      cursor: pointer;
    }
  }
}

.custom-control.custom-radio,
.custom-control.custom-checkbox,
.custom-control.custom-switch {
  .custom-control-label {
    line-height: 24px;
  }
}

.header {
  width: 100%;
  height: 21px;
  background-color: getColor("primary");
  &::after {
    content: url("../assets/wave.svg");
    position: absolute;
    top: 20px;
    z-index: -1;
    width: 35%;
  }
}

.confirmation-modal {
  .modal-content {
    max-width: 320px;
    @include marginX(auto);
  }
}

.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  padding-top: 600px / 600px * 100%;
  background: white;
  position: relative;
}
.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.flexbox-centering {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 50%;
}

.btn-hover {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.react-select__control {
  width: 100% !important;
}

.center-spinner {
  position: absolute;
  @include centerAbsolute;
}

.right-spinner {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.edit-picture {
  padding: 0.25rem !important;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  bottom: 0px;
  right: 5px;
  box-shadow: none !important;
  svg {
    position: absolute;
    @include centerAbsolute;
  }
}

.error-tab {
  background-color: #f94343 !important;
}
.form-control {
  padding: 0px 5px 0px 5px !important;
}

.long-title h3 {
  font-family: "Segoe UI Light", "Helvetica Neue Light", "Segoe UI", "Helvetica Neue", "Trebuchet MS", Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
textarea:focus,
input:focus {
  outline: none;
}
.scrollbar {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  scrollbar-width: thin;
}
.details-scrollbar {
  height: calc(100vh - 100px);
  padding-bottom: 3rem;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activity-tiles {
  padding: 5px;
}

.long-title {
  margin-bottom: 60px;
}
.dx-button-content .dx-scheduler-appointment-collector-content {
  padding-top: 2px !important;
}

.dx-button .dx-button-content {
  padding-top: 2px !important;
}
.dx-scheduler-appointment-content {
  button {
    background-color: transparent;
    width: 100%;
    color: #fff;
    padding-top: 5px;
  }
}
.spinner-nav-zoom {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  color: "#2b60fa";
}

.inquiry-description {
  white-space: nowrap;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.widget-preview-img {
  height: 250px;
  width: 100%;
  object-fit: contain;
}

.mvh-100 {
  min-height: 100vh;
}

.custom-select.custom-select-sm {
  background-position: right 15px top 13px;
}

.modal-header {
  .close {
    outline: 0;
    span {
      font-size: 1.5rem;
    }
  }
}
button.close {
  outline: none;
}

.word-break-all {
  word-break: break-all;
  white-space: pre-line;
}

.nowrap {
  white-space: nowrap;
}

select[name="countryCode"] {
  width: 75px !important;
  flex: 0 0 75px !important;
  border: none;
}

.progress-sm {
  height: 0.5rem;
}

.img-preview {
  height: 120px;
  object-fit: cover;
}

.profile-image-default {
  width: 100px;
  height: 100px;
  object-fit: scale-down;
  background-color: #eee;
}
.profile-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  background-color: #eee;
}

.react-datepicker-popper {
  z-index: 5 !important;
}
.custom-control-label::before,
.custom-control-label::after {
  z-index: 0;
}

.form-control.is-disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
}

.title-case {
  text-transform: capitalize;
}

.input-group-append {
  .custom-select {
    border: none;
    border-radius: 0 0.5rem 0.5rem 0;
  }
}
.btn.btn-link {
  outline: none;
  &:focus {
    box-shadow: none;
  }
}

.hr-divider {
  border: 0.1rem solid #eee;
  background-color: #eee;
  border-radius: 1px;
  width: 100%;
  margin: 1rem 0rem;
}
.MuiButtonBase-root.MuiIconButton-root {
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}
.btn-up-vote {
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  transition: all 0.5s;
  outline: none;

  &:hover,
  &:focus {
    outline: none;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.btn-see-more {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: map-get($theme-colors, "primary");
  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
}
.img-signature {
  width: auto;
  height: 40px;
}

.crop-modal {
  .modal-dialog {
    transform: translateY(0) !important;
  }
  .modal-body {
    .crop-container {
      height: 40vh;
      position: relative;
    }
  }
}
.reactEasyCrop_Container {
  border-radius: 1.5rem;
}

.input-group-prepend.disabled {
  span {
    color: #a5b0bb;
  }
}

.input-group-append.disabled {
  span {
    color: #a5b0bb;
  }
}
.react-autosuggest__suggestions-list {
  margin: 0;
  li {
    margin: 5px;
    padding-top: 5px;

    &:first-child {
      padding-top: 8px;
      border-top: 1px solid #e2e2e2;
    }
  }
}

.error-container {
  height: calc(100vh - 60px);
  position: relative;
  .error-body {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 100%;
      max-width: 400px;
    }
    span.error-code {
      color: #3b4773;
      font-size: 60px;
    }
    p {
      color: "#3B4773";
      font-size: 26px;
    }
  }
}
.error-container.full {
  height: 100vh;
}
.bg-error {
  background-color: #f2f6ff;
}

.white-space-normal {
  white-space: normal !important;
}

.dropdown-toggle-btn {
  padding: 0px 0.5rem !important;
  box-shadow: none !important;
  background-color: transparent;
  &::after {
    display: none;
  }
}

.form-active-step {
  display: block !important;
}
.form-step-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    font-size: 12px;
  }
}
.color-palette-colors {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .show-on-hover {
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  &:hover {
    .show-on-hover {
      opacity: 1;
    }
  }
}

@media (max-width: 768px) {
  h6.modal-header-item {
    font-size: 14px;
  }
  button.modal-header-item {
    font-size: 10px;
  }
}

@media (max-width: 320px) {
  h6.modal-header-item {
    font-size: 12px;
  }
  button.modal-header-item {
    font-size: 8px;
  }
}

.coupon-code {
  color: #2c60fa;
  background-color: #d9e2fa;
  padding: 10px 20px;
  font-size: 12px;
  display: inline-block;
  border-radius: 7px;
  margin-left: 10px;
}

.cover-img {
  .business-cover-btn {
    float: right;
  }
}

.modal-popover {
  z-index: 1070 !important;
}

.table-spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background-color: rgba(map-get($theme-colors, "primary"), 0.25);
  border-radius: 0.5rem;
  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .spinner-border {
      border-width: 0.4rem;
      width: 4rem;
      height: 4rem;
    }
  }
}
.invoice-iframe {
  height: calc(100vh - (60px + 120px));
  width: 100%;
}

.invoice-history-list {
  height: 50vh;
  overflow-y: scroll;
}
.react-datepicker__close-icon {
  right: 5px !important;
  &::after {
    background-color: #000000 !important;
    height: 18px !important;
    width: 18px !important;
    font-size: 13px !important;
  }
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  background-color: rgb(242, 242, 242);
  color: rgb(153, 153, 153) !important;
}
.z-1070 {
  z-index: 1070 !important;
}

.dropdown-item.active,
a.dropdown-item:active {
  background-color: #f8f9fa;
}

.notes-textarea {
  font-size: 0.875rem;
}

.emergency-contacts-card {
  button.emergency-contacts-add-button {
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  &:hover {
    button.emergency-contacts-add-button {
      opacity: 1;
    }
  }
}

.slots-separator {
  border-bottom: 1px solid #e0e0e0;
}

.img-xs {
  width: 35px !important;
  height: 35px !important;
  object-fit: cover;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2b60fa;
  color: #2b60fa;
  box-shadow: 9999px 0 0 -5px #2b60fa;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2b60fa;
  color: #2b60fa;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #2b60fa;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #2b60fa;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #2b60fa;
  }
  30% {
    box-shadow: 9984px 0 0 2px #2b60fa;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #2b60fa;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #2b60fa;
  }
  30% {
    box-shadow: 9999px 0 0 2px #2b60fa;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #2b60fa;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #2b60fa;
  }
  30% {
    box-shadow: 10014px 0 0 2px #2b60fa;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #2b60fa;
  }
}

.w-60px {
  width: 60px !important;
}

.text-line-through {
  text-decoration: line-through;
}
