.people-profile-image {
  position: relative;
  max-width: 200px;
  margin: 0 auto;
  label {
    position: absolute;
    bottom: 0%;
    right: 17%;
    background-color: white;
    padding: 0.5rem;
    @include shadow(0.5);
    border-radius: 50%;
    height: 38px;
    width: 38px;
    > svg {
      position: absolute;
      @include centerAbsolute();
    }
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.people-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-container {
    height: 70px;
    width: 70px;
  }
}

.badge-parent {
  background-color: rgba($color: getColor("warning"), $alpha: 0.25);
  color: getColor("warning");
  padding: 0.5rem 1rem;
}

.badge-kid {
  background-color: rgba($color: getColor("success"), $alpha: 0.25);
  color: getColor("success");
  padding: 0.5rem 1rem;
}

.btn-user-select {
  background-color: transparent;
  &:hover {
    background-color: rgba(map-get($theme-colors, "primary"), 0.2);
  }
  &:active {
    background-color: map-get($theme-colors, "primary");
  }
}

.modal-users {
  .modal-content {
    margin-top: 1.5rem;
    @include shadow(0.2);
  }
}

.people_filter_button {
  color: #2c60fa !important;
}

.people_filter_button:hover {
  color: white !important;
}

.filter-badges {
  margin-top: 1rem;
}
.filter-badges .filter-badge {
  background-color: #2c60fa;
  border-radius: 8px;
  box-shadow: 0 0 20px #dedede;
  color: #fff;
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  padding: 0.5rem 0.825rem;
}

.filter-badges .filter-badge button {
  background-color: transparent;
  border: 0;
  color: #fff;
  margin-left: 0.75em;
  outline: 0;
}
