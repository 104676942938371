.statistics {
  padding: 1.5rem;
  @include border(0.8rem);
  > div {
    @include paddingY(1rem);
    &:not(:first-child) {
      border-top: 1.2px solid getColor("grey-light-4");
    }
  }
  h3 {
    color: getColor("grey-light-3");
  }
  p,
  span {
    font-family: $font-family-poppins;
    vertical-align: text-bottom;
  }
  .count {
    color: getColor("primary");
    font-size: 2.4rem;
    line-height: 2.5rem;
    font-weight: 600;
  }
  .change {
    line-height: 32px;
  }
  .round {
    width: 18px;
    height: 18px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    line-height: 18px;
    img {
      vertical-align: middle;
    }
  }
}

.chart-box {
  background: getColor("white");
  @include border(0.8rem);
  padding: 20px 15px;
}

.line-chart > div {
  height: 150px;
}

@media (min-width: 768px) {
  .statistics {
    > div {
      @include paddingY(0rem);
      @include marginY(0.5rem);
      &:nth-child(even) {
        border-top: none;
        border-left: 1.2px solid getColor("grey-light-4");
      }
      &:not(:first-child) {
        border-top: none;
      }
    }
  }
}

@media (min-width: 992px) {
  .statistics {
    > div {
      @include paddingY(0rem);
      @include marginY(0rem);
      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
      }
      &:not(:first-child) {
        border-top: none;
        border-left: 1.2px solid getColor("grey-light-4");
      }
    }
  }
}

.switch-modal {
  .modal-dialog {
    transform: translateY(0) !important;
  }
  p {
    font-size: medium;
  }
}
