.login {
  .form-header {
    margin-top: 0rem;
  }
  .logo {
    margin-top: 4rem;
  }
  .signup {
    margin-top: 2rem;
  }
}

@media (min-width: 768px) {
  .login {
    .form-header {
      margin-top: 2rem;
    }
    .logo {
      margin-top: 8rem;
    }
    .signup {
      margin-top: 4rem;
    }
  }
}

@media (min-width: 992px) {
  .login {
    .form-header {
      margin-top: 6rem;
    }
    .logo {
      margin-top: 10rem;
    }
  }
}
