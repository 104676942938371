.toast {
  background-color: transparent;
  border: none;
}
.toast-top-left {
  position: fixed;
  top: 30px;
  left: 10px;
}
.toast-top-right {
  position: fixed;
  top: 30px;
  right: 10px;
}
.toast-top-center {
  position: fixed;
  top: 30px;
  transform: translateX(-50%);
  left: 50%;
}
.toast-bottom-left {
  position: fixed;
  bottom: 20px;
  left: 10px;
}
.toast-bottom-right {
  position: fixed;
  bottom: 20px;
  right: 10px;
}
.toast-bottom-center {
  position: fixed;
  bottom: 20px;
  transform: translateX(-50%);
  left: 50%;
}
