.rating {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0rem;
  button {
    border: none;
    padding: 0.2rem 0.2rem;
    background-color: transparent;
    font-size: 0.6rem;
    outline: 0;
    vertical-align: middle;
    svg {
      display: block;
    }
  }
}
