.navbar {
  height: 60px;

  .navbar-toggle {
    background-color: transparent;
    box-shadow: none !important;
    padding: 0px;
    padding-right: 0.5rem;
    border: 0;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    img {
      height: 40px;
      object-fit: cover;
    }
    &::after {
      display: none;
    }
  }
}

.navbar-toggler {
  border: 0px;
  @include paddingX(0.25rem);
}

.img-nav-profile {
  width: 22px;
  height: 22px;
}
.spinner-nav-profile {
  width: 22px;
  height: 22px;
  margin-right: 20px;
}
.business-name {
  display: inline-block;
  margin-bottom: 0;
  color: #fff;
  transform: translateY(6px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  margin-left: 10px;
  font-weight: 600;
  text-align: left;
}

.dropdown-item-switch-business {
  padding: 0.25rem 0.75rem;
  &:active {
    background-color: #f8f9fa;
  }
  &:focus {
    outline: none;
  }
}
.dropdown-menu-switch-business {
  top: calc(0px - 1px);
  left: -1px;
  border: none;
  padding: 0;
  .dropdown-scoller {
    max-height: 40vh;
    overflow-y: scroll;
  }
}
.tag-admin-type {
  display: block;
  margin-top: -2px;
}
.cursor-default {
  cursor: default !important;
}
.navbar-toggle {
  background: transparent !important;
  display: flex;
}
.logged-in-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dropdown-divider {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .business-name {
    max-width: 150px;
  }
}
