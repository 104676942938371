.plans {
  .plan {
    background: getColor("white");
    box-shadow: 0 0 10px #ccc;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    p {
      font-weight: normal;
      color: #808080;
    }

    h4 {
      font-weight: 500;
      margin-bottom: 24px;
    }
  }
}

.btn-plan {
  margin-top: auto;
  // transform: translateY(50%);
}

.card-icon {
  height: 15px;
}
