.profile {
  .profile-box {
    .profile-plan {
      .plan-type {
        background: #e1e9ff;
        border-radius: 0.3rem;
        font-weight: bold;
        font-size: getFontSize("lg");
        color: getColor("primary");
        border: none;
      }
      .expiry {
        font-family: $font-family-muli;
        font-size: getFontSize("sm");
        color: #a5b1d1;
      }
    }
    .profile-logo {
      transform: translate(0%, -50%);
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      overflow: hidden;
      border: 0.7rem solid #fff;
      background-color: #fff;
      margin: 0 20% -20%;
      position: relative;

      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
        opacity: 0;
        transition: 0.3s ease-in-out;
      }

      .edit-photo-section {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        opacity: 0;
        transition: 0.3s ease-in-out;
      }

      &:hover {
        .overlay {
          opacity: 1;
        }
        .edit-photo-section {
          opacity: 1;
        }
      }
    }
    .cover-img {
      border-radius: 1rem;
      background-size: cover !important;
      position: relative;
      height: 300px;

      .btn-outline-white {
        color: getColor("white");
        border: 1px solid getColor("white");
        box-shadow: none;
      }

      .thumbnails {
        text-align: right;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        .thumbnail {
          border-radius: 12px;
          width: 100%;
          width: 50px;
          height: 50px;
          object-fit: cover;
        }
        ul {
          width: 100%;
          display: inline-block;
          li {
            display: inline-block;
            margin-left: 10px;
            position: relative;
            .delete {
              // position: absolute;
              // top: 0.3rem;
              // right: 0.3rem;
              background-color: rgba(getColor("danger"), 0.5);
              border-radius: 0.2rem;
              border: 0px;
              padding: 5px;
              opacity: 0.9;
              color: getColor("danger");
              &:hover {
                background-color: rgba(getColor("danger"), 1);
                color: #fff;
                opacity: 1;
              }
              img {
                width: 12px;
                height: 12px;
                display: block;
              }
            }
            .more {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: white;
              font-weight: bold;
              span {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
  .form-control-plaintext {
    @include paddingX(0.5rem);
    border: 1px solid getColor("grey");
    &:read-only,
    &:disabled {
      border: none;
      padding: 0px;
    }
    &.profile-name {
      font-family: $font-family-poppins;
      color: getColor("grey-light-1");
      font-size: 1.4rem;
    }
    &.profile-address {
      color: getColor("grey-light-1");
    }
  }
  .user-details {
    .form-label {
      font-size: getFontSize("md");
      color: getColor("grey-light-2");
      font-weight: bold;
    }

    .form-control-plaintext {
      font-size: getFontSize("md");
      color: #929fc6;
    }

    textarea {
      resize: none;
    }
  }
}

.edit-pictures {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .picture {
    position: relative;
    width: 30%;
    margin: 5px;
    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
    button {
      position: absolute;
      top: 0.5rem;
      right: 0.7rem;
      display: block;
      background: none;
      padding: 0.1rem 0.2rem;
      border-radius: 0.2rem;
      border: 0;
      background-color: getColor("danger");
    }
  }
  .btn-add-more {
    align-self: center;
  }
}

@media (min-width: 768px) {
  .profile {
    .profile-box {
      .cover-img {
        .thumbnails {
          .thumbnail {
            width: 55px;
            height: 55px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .profile {
    .profile-box {
      .cover-img {
        .thumbnails {
          .thumbnail {
            width: 70px;
            height: 70px;
          }
        }
      }
    }
  }
}

.overlay-image {
  position: relative;
  overflow: hidden;
  &:hover {
    .overlay {
      display: flex;
    }
  }
  .overlay {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
  }
}

.delete-btn-profile-image {
  background-color: rgba(getColor("danger"), 0.3);
  border-radius: 0.2rem;
  border: 0px;
  padding: 5px;
  color: getColor("danger");
}

.profile-tabs {
  margin-bottom: 1rem;
  border: 0 !important;
  .nav-item.nav-link {
    outline: 0px;
    border-radius: 0px;
    &.active {
      background-color: getColor("primary");
      color: getColor("white");
    }
  }
  + .tab-content {
    background-color: #f5f8ff;
    border-radius: 0.5rem;
    padding: 1.5rem;
    .nav-tabs {
      margin-bottom: 1rem;
      a.nav-item.nav-link {
        border: 0;
        color: #929fc6;
        &.active {
          background-color: transparent;
          color: #445380;
          font-weight: 700;
          border-bottom: 2px solid map-get($theme-colors, "primary");
        }
      }
    }
  }
}

.profile-user-image {
  max-height: 180px;
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.badge-keyword {
  background-color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
  margin-left: 0.5rem;
  button {
    margin-left: 0.5rem;
  }
}

.input-group-plain {
  box-shadow: none !important;
  outline: none !important;
  border: 0px !important;
  .custom-select {
    background-color: transparent !important;
  }
}
