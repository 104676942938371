.activities-details {
  height: calc(100vh - 15px - 6rem);
  overflow: hidden;
  padding-bottom: 3rem;
  .list-header {
    padding-left: 0.75rem;
  }
}

.activities-card {
  position: relative;
  // background: url(../assets/clay_time.png) no-repeat center;
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 23px 23px 0 0;
  padding: 20px;
  height: 300px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
  &:hover {
    &:after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%);
    }
  }
  &:after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 50%);
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    z-index: 1;
    content: "";
  }
  .bottom-details {
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
    text-align: center;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
  }
  .buttons {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    @include paddingX(1rem);
    margin-top: 1rem;
    z-index: 3;
    .btn-editor {
      width: 142px;
    }
  }
  & + .text-control .form-control-plaintext {
    color: getColor("grey-light-2");
    font-size: getFontSize("sm");
    @include paddingX(0.5rem);
    border: 1px solid getColor("grey");
    resize: none;
    outline: 0;
    &:read-only {
      border: none;
      padding: 0px;
    }
  }
}

.thumbnails {
  text-align: right;
  .thumbnail {
    border-radius: 12px;
    width: 100%;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    li {
      display: inline-block;
      margin-left: 10px;
      position: relative;
      .delete {
        position: absolute;
        top: 0.3rem;
        right: 0.3rem;
        background-color: rgba(getColor("black"), 0.5);
        border-radius: 50%;
        border: 0px;
        padding: 5px;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
        img {
          width: 12px;
          height: 12px;
          display: block;
        }
      }
      .more {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-weight: bold;
        span {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.activities-details-tab {
  .nav-item.nav-link {
    outline: 0px;
    border-radius: 0px;
    &.active {
      background-color: getColor("primary");
      color: getColor("white");
    }
  }
}

.add-more {
  padding: 10px;
  background-color: "#2c60fa";
  border-radius: 30px;
}

.tab-form-header {
  color: getColor("grey-light-2");
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    background: getColor("success");
    text-align: center;
    line-height: 25px;
    margin-right: 5px;
  }
}
.photos-count {
  color: rgba(69, 207, 69, 0.925);
  margin-bottom: 6px;
  display: inline;

  text-align: right;
  display: block;
}

.student-list {
  .student-list {
    h6 {
      font-size: getFontSize("md");
    }
    p {
      font-size: 0.8rem;
    }
    .btn-rm,
    .btn-rv {
      width: 20px;
      height: 20px;
      border: 0px;
      border-radius: 50%;
      padding: 0.3rem;
      cursor: pointer;
      vertical-align: middle;
      img {
        display: block;
        width: 10px;
        height: 10px;
      }
    }
    .btn-rm {
      background: getColor("danger");
    }
    .btn-rv {
      background: getColor("primary");
    }
  }
}

.inactive-tooltip {
  .tooltip-inner {
    background: #fff2cb;
    border-radius: 4px;
    font-size: 11px;
    color: #99823d;
    padding: 8px 15px;
  }
  .arrow::before {
    border-top-color: #fff2cb !important;
  }
}
.inactive-bullet {
  color: #ffc92e;
  font-size: 1.2rem;
  margin-left: 0.3rem;
}

.opacity-half {
  opacity: 0.5;
}

.form-control-title {
  .form-control {
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.image-uploader {
  width: auto !important;
  display: inline-block;
  .fileContainer {
    background-color: transparent;
    width: auto;
    display: inline-block;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    p {
      display: none;
    }
    .chooseFileButton {
      margin: 0px;
      background-color: transparent;
      color: getColor("primary");
      border: 1px solid getColor("primary");
    }
  }
}

.preview-list {
  h6 {
    font-family: $font-family-muli;
  }
  &:hover {
    background: #f1f4fe;
  }
}
.delete-button {
  margin-bottom: 10px;
}
.activity-title {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.calendar-activity-title {
  white-space: nowrap;
  width: 55%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.calendar-tooltip-title {
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: bold;
}
.calendar-seat-information {
  position: absolute;
  top: 0.2rem;
  right: 11px;
}
.calendar-activity-time {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.activity-card-title {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  // padding-right: 20px;
}

.react-tagsinput {
  border: none !important;
  background-color: transparent !important;
  .react-tagsinput-tag {
    border: none !important;
  }
  .react-tagsinput-tag {
    padding: 1px 8px;
    padding-bottom: 2px !important;
    background-color: #3cdb8f !important;
    color: #000;
    border-radius: 5px;
    margin: 5px;
    margin-right: 0;
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .react-tagsinput-input {
    width: 100% !important;
  }
}
.react-tagsinput-tag a {
  &::before {
    content: " ×";
    cursor: pointer;
  }
  &:hover {
    text-decoration: none;
    &::before {
      font-weight: bold;
    }
  }
}
.no-border {
  border: none !important;
}

.publish-activity-btn-con {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  .publish-activity-btn {
    width: 200px;
  }
}

.activities-card {
  .activity-images {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    cursor: pointer;

    img {
      border: 0.2rem solid #fff;
      height: 60px;
      width: 60px;
      object-fit: cover;
    }
    img:not(:first-child),
    img:not(:last-child) {
      margin: 0 0.5rem;
    }
  }
  .expand-button {
    position: absolute;
    top: 20px;
    right: 20px;
    display: none;
    z-index: 10;
  }

  &:hover {
    .expand-button {
      display: block;
    }
    .set-featured-btn {
      display: block;
    }
  }

  .active-thumb {
    border: 0.2rem solid #3cdb8f !important;
  }
  .set-featured-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
    display: none;
  }
}

.activity-tabs-container {
  box-shadow: inset 0px 10px 20px #eee;
}

.select-student-container {
  max-height: 450px;
  overflow-y: auto;
}

.upload-document {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  input[type="file"] {
    display: none;
  }
}

.activity-card-details {
  white-space: nowrap;
  label.custom-control-label {
    white-space: nowrap;
  }
}

.activity-plan-select {
  label {
    border: 1px solid map-get($theme-colors, "primary");
    border-radius: 1rem;
    padding: 1rem;

    &.active {
      background-color: rgba(map-get($theme-colors, "primary"), 0.1);
      border: 0;
    }
  }
}
