.engagement {
  .description {
    h6 {
      font-family: $font-family-muli;
      color: getColor("grey-light-3");
    }
    p {
      color: getColor("grey-light-3");
      font-family: $font-family-muli;
    }
  }
  .target-audience-form {
    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      background-image: none !important;
    }
  }
  .activity-list-item {
    position: relative;
    .thumbnail {
      width: 100%;
      max-width: 60px;
      max-height: 60px;
      object-fit: cover;
      border-radius: 0.5rem;
    }
    .star-icon {
      display: block;
      position: absolute;
      left: 5px;
      top: 5px;
      width: 16px;
      height: 16px;
      background: #fff;
      border-radius: 50%;
      padding: 0.1rem;
    }
    h6 {
      font-size: 0.9rem;
    }
  }
  .custom-checkbox .custom-control-label::before {
    border-radius: 50%;
  }
  .border-right {
    border-right: none !important;
  }
}

@media (min-width: 768px) {
  .engagement {
    .border-right {
      border-right: 1px solid #dee2e6 !important;
    }
  }
}

@media (min-width: 992px) {
  .engagement {
    .border-right {
      border-right: 1px solid #dee2e6 !important;
    }
  }
}
