@import "styles/color";
@import "styles/fonts";
@import "styles/mixins";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/react-google-places-autocomplete/dist/index.min.css";
@import "../node_modules/react-datepicker/dist/react-datepicker.min.css";
@import "../node_modules/react-toastify/dist/ReactToastify.css";
@import "../node_modules/react-sharingbuttons/dist/main.css";
@import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// custom styles
@import "styles/custom";
@import "styles/popover";
@import "styles/toast";

// global styles
body,
p,
span,
input,
button,
th,
td,
a {
  font-family: $font-family-muli;
  font-size: getFontSize("md");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-poppins;
  color: getColor("grey-light-1");
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.border-0 {
  border: 0px !important;
}

// component partials
@import "styles/navbar";
@import "styles/sidebar";
@import "styles/dashboard";
@import "styles/signup";
@import "styles/searchbar";
@import "styles/rating";
@import "styles/login";
@import "styles/payment";
@import "styles/profile";
@import "styles/people";
@import "styles/activities";
@import "styles/subscription";
@import "styles/engagement";
@import "styles/transaction";
@import "styles/discount";
@import "styles/chat";
@import "styles/activityView";
@import "styles/invoice";
@import "styles/activityform";
@import "styles/settings";
@import "styles/pagination";

*::-webkit-scrollbar {
  width: 0.4em;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba($color: getColor("black"), $alpha: 0.2);
  border-radius: 0.5rem;
}

label {
  color: getColor("primary-dark");
  font-weight: 600;
}

.btn {
  font-family: $font-family-muli;
  font-size: getFontSize("md");
  box-shadow: 0px 4px 10px #ededed;
  border-radius: 1.5rem;
  @include paddingX(1.5rem);
  &[class*="btn-outline-"] {
    &:disabled {
      color: #616161;
    }
  }
}
.btn-link {
  box-shadow: none;
}
.btn-success {
  color: getColor("white");
  &:disabled {
    color: getColor("white");
  }
}

.form-control {
  font-family: $font-family-muli;
  background: getColor("white");
  @include shadow(0.25);
  border-radius: 0.5rem;
  border: none;
  &:focus {
    @include shadow(0.4);
    border: 1px solid rgba(getColor("primary"), 0.44);
  }
  &::placeholder {
    color: #bababa;
    font-family: $font-family-muli;
  }
}

.card {
  border: 0px;
  @include shadow(0.25);
}

select.form-control:disabled {
  color: getColor("grey");
  background-color: getColor("white");
}
.input-group {
  border: 1px solid rgba(getColor("primary"), 0);
  border-radius: 0.5rem;
  outline: 0;
  @include shadow(0.25);
  &:hover,
  &:active,
  &:focus {
    @include shadow(0.4);
    border: 1px solid rgba(getColor("primary"), 0.44);
    .input-group-append {
      .btn {
        &.btn-outline-primary {
          color: getColor("primary");
          &:focus,
          &:hover {
            color: getColor("white");
          }
        }
      }
    }
  }
  .form-control {
    box-shadow: none;
    &:focus {
      box-shadow: none;
      border: none;
    }
  }
  .input-group-text {
    background: getColor("white");
    border-radius: 0.5rem;
    border: 0;
  }
  .input-group-append {
    .btn {
      color: getColor("white");
      box-shadow: none;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border: 0;
    }
  }
}

.dropdown .dropdown-menu .dropdown-item {
  &:active > svg {
    color: #fff !important;
  }
}

.main-container {
  width: 100%;
  position: relative;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-calendar-picker-indicator {
  margin: 0;
  // display: none;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.loading-indicator:before {
  content: "";
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-left: 0;
  z-index: 3000;
  margin-top: 60px;
  border-top-left-radius: 3.7rem;
}

.loading-indicator:after {
  content: "Loading...";
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 3001;
  color: #2c60fa;
  margin-left: 0;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.processing-indicator:before {
  content: "";
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-left: 0;
  z-index: 3000;
  margin-top: 60px;
  border-top-left-radius: 3rem;
}
.processing-indicator:after {
  content: "Processing...";
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 3001;
  color: #2c60fa;
  margin-left: 0;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.loading-indicator-body:before {
  content: "";
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-left: 0;
  z-index: 3002;
  // margin-top: 60px;
  // border-top-left-radius: 3rem;
}
.loading-indicator-body:after {
  content: "Loading...";
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 3003;
  color: #2c60fa;
  margin-left: 0;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}
@media (max-width: 1400px) {
  .allowed-classes {
    display: block;
  }
}
@media (min-width: 992px) {
  .loading-indicator:before {
    content: "";
    background: #fff;
    position: fixed;
    width: calc(100% - 250px);
    height: 100%;
    top: 0;
    left: 0;
    margin-left: 250px;
    z-index: 3000;
    margin-top: 60px;
    border-top-left-radius: 3.7rem;
  }

  .loading-indicator:after {
    content: "Loading...";
    position: fixed;
    width: calc(100% - 300px);
    top: 50%;
    left: 0;
    z-index: 3001;
    color: #2c60fa;
    margin-left: 250px;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .processing-indicator:before {
    content: "";
    background: #fff;
    position: fixed;
    width: calc(100% - 300px);
    height: 100%;
    top: 0;
    left: 0;
    margin-left: 250px;
    z-index: 3000;
    margin-top: 60px;
    border-top-left-radius: 3rem;
  }
  .processing-indicator:after {
    content: "Processing...";
    position: fixed;
    width: calc(100% - 300px);
    top: 50%;
    left: 0;
    z-index: 3001;
    color: #2c60fa;
    margin-left: 250px;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.time-keeper-modal {
  .modal-content {
    background: transparent;
    border: 0;
  }
  .react-timekeeper {
    margin: 0 auto;
  }
}
.image-loader {
  color: #2c60fa;
  vertical-align: middle;
  text-align: center;
}
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.google-places-autocomplete__suggestion {
  cursor: pointer;
}

.edit-pictures-btn {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.activity-save-btn {
  color: #212529;
  background-color: #3cdb8f;
  border-color: #3cdb8f;
}

.modal.show .modal-dialog {
  margin-top: 0;
  transform: translateY(100px);
}

.tooltip-icon-container {
  margin-left: 5px;
}
.tooltip-text {
  text-align: left !important;
}
.custom-select:disabled {
  color: #000 !important;
}

.slots {
  .form-check-input {
    margin-top: 0.2rem !important;
  }
}

.list-group-item {
  cursor: pointer;
}
.css-1pahdxg-control {
  border: none !important;
  box-shadow: none !important;
}
.css-yk16xz-control {
  border: none !important;
}

.mouse-grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.child-name-ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
}
.css-26l3qy-menu {
  z-index: 100 !important;
}
.lazy-loading-indicator {
  background: #fff;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 3001;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    color: #2c60fa;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
  }
}
.react-tagsinput {
  width: 100%;
}
.color-description-con {
  padding: 15px;
  margin-top: 15px;
  h6 {
    margin-bottom: 0;
  }
}
.color-description-con h6 {
  font-size: 14px;
}
.color-box {
  height: 30px;
  width: 40px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.dx-scheduler-date-table-cell {
  height: 100px;
}
.dx-scheduler-time-panel-cell {
  height: 100px;
}
.dx-scheduler-time-panel {
  margin-top: -50px;
}
.dx-scheduler-appointment.dx-scheduler-appointment-empty {
  min-height: 50px;
}
.dx-scheduler-appointment {
  min-height: 50px;
}

.attendance-table-header {
  border-bottom: 1px solid #dee2e6 !important;
  color: #979eb4;
  font-weight: 400;
}

.spinner-loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
