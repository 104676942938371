* {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  outline: none;
}
.pt20 {
  padding-top: 20px;
}
// .activities-card {
//   background-image: url(http://twishr-api-dev.codebuddy.review/activity/photos-Chrysanthemum.jpg);
// }
.activities-card .buttons .btn-editor {
  width: 142px;
}
.edit-pictures-btn {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.control_descrip {
  padding: 20px 0;
}
.control_descrip p {
  color: #445380;
  margin-bottom: 25px;
}
.control_descrip h2 {
  font-size: 24px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 0;
}

.thumbBox img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
}

.descripe {
  margin-top: 20px;
}
.text-primary {
  color: #2c60fa !important;
}
.tab-form-header {
  color: #445380;
}
.tab-form-header span {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background: #3cdb8f;
  text-align: center;
  line-height: 25px;
  margin-right: 5px;
}
.filderSec {
  font-size: 20px;
}
.colfield label {
  color: #000;
  font-size: 16px;
  width: 20%;
}
.colfield {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.colfield span {
  color: #445380;
  font-size: 16px;
}
.colfieldFull p {
  color: #445380;
  font-size: 16px;
}
.colfield2 {
  color: #445380;
  font-size: 16px;
}

.colGrni {
  color: #3cdb8f;
}
.close_red {
  color: red;
}
.colfield2 .iconEye {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background: #3cdb8f;
  text-align: center;
  line-height: 25px;
  margin-right: 5px;
}

.bx-sdw {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
}
.bx-sdw2 {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 20px 5px 0;
  margin-bottom: 15px;
}
.daySec {
  width: auto;
  background: #3cdb8f;
  padding: 4px 20px;
  display: inline-block;
  color: #fff;
}
.timePkr {
  padding: 10px 0 0 20px;
}
.timePkr label {
  color: #000;
  font-size: 16px;
  width: 20%;
}
.timePkr span {
  color: #445380;
  font-size: 16px;
}
.scrollSec {
  height: 480px;
  overflow: auto;
  padding: 15px;
}
/* width */
.scrollSec::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scrollSec::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollSec::-webkit-scrollbar-thumb {
  background: #2c60fa;
}

/* Handle on hover */
.scrollSec::-webkit-scrollbar-thumb:hover {
  background: #2c60fa;
}
