@use "sass:map";

@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Muli:400,500,600,700&display=swap");

$font-family-poppins: "Poppins", sans-serif;
$font-family-muli: "Muli", sans-serif;

$font_sizes: (
  "sm": 0.7rem,
  "md": 0.9rem,
  "lg": 1rem,
);

@function getFontSize($key) {
  @if not map-has-key($font_sizes, $key) {
    @return map-get($font_sizes, "md");
  }
  @return map-get($font_sizes, $key);
}

.font-poppins {
  font-family: $font-family-poppins !important;
}
.font-muli {
  font-family: $font-family-muli !important;
}
