.invoice-format-drop {
  border: 2px solid getColor("primary");
  border-radius: 0.5rem;
  padding: 0.5rem;
  min-height: 80px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.form-control-color {
  width: 60px !important;
}

.color-preview {
  width: 80px;
  height: 1.5rem;
  margin: 0.5rem 0rem;
  display: inline-block;
  box-shadow: 0px 0px 8px #bdbdbd;
  border-radius: 0.4rem;
}
