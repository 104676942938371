.sidebar {
  width: 250px;
  position: fixed;
  transition: all 0.3s;
  .sidebar-container {
    overflow-y: auto;
  }
  .corner {
    transition: all 0.3s;
    position: fixed;
    width: 60px;
    height: 60px;
    top: 60px;
    left: 250px;
    z-index: 100;
  }
  .nav {
    margin-top: 3rem;
    height: calc(100vh - 60px - 3rem);
    a,
    button {
      outline: 0;
      text-align: left;
      background: transparent;
      border: 0;
      width: 100%;
      padding: 0.8rem 1rem 0.8rem 2.5rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      position: relative;
      margin: 0.25rem 0px;
      margin-bottom: 0px;
      span {
        font-size: 0.875rem;
      }

      &:hover {
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
        color: getColor("white");
      }

      &.active {
        font-weight: bold;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
        color: getColor("white");
      }
      img {
        margin-right: 1rem;
      }
    }
    > a,
    button {
      .sidebar-new-dot {
        left: 1rem;
      }
    }
  }

  &.hide {
    width: 80px;
    span {
      display: none;
    }
    .nav-item {
      display: none;
    }
    .corner {
      left: 80px;
    }
    + .view-container {
      margin-left: 80px;
      width: calc(100% - 80px);
    }
    + .processing-indicator:before,
    + .processing-indicator:after,
    + .loading-indicator:before,
    + .loading-indicator:after {
      width: calc(100% - 80px);
      margin-left: 80px;
    }

    .menu {
      .toggle {
        display: none;
      }
    }
    .menu-content {
      a {
        padding-left: 2.5rem;
      }
      img {
        display: inline-block;
      }
      span {
        display: none;
      }
    }
    .sidebar-new-dot {
      left: 1rem;
    }

    &.hide:hover {
      width: 250px;
      + .view-container {
        margin-left: 250px;
        width: calc(100% - 250px);
      }
      span {
        display: inline;
      }
      .nav-item {
        display: block;
      }
      .corner {
        left: 250px;
      }
      .menu {
        .toggle {
          display: inline-block;
        }
      }
      .menu-content {
        a {
          padding-left: 4.8rem;
        }
        img {
          display: none;
        }
        span {
          display: inline-block;
        }
      }
    }
  }

  .sidebar-container::-webkit-scrollbar {
    display: none;
  }

  .menu {
    .toggle {
      transition: transform 0.3s;
      &.invert {
        transform: rotate(90deg);
      }
    }
  }
  .menu-content {
    a {
      padding-left: 4.8rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin: 0px;
    }
    span {
      font-size: 0.75rem !important;
    }
    &.closed {
      max-height: 0px;
      overflow: hidden;
    }
    &.open {
      transition: max-height 0.3s linear;
      max-height: 100vh;
    }
    img {
      display: none;
    }
  }
  .menu-content,
  .menu {
    margin-bottom: 0px !important;
    &.open {
      background-color: rgba(151, 177, 255, 0.5) !important;
    }
  }
}

.view-container {
  background: getColor("white");
  transition: all 0.3s;
  float: left;
  z-index: 0;
  margin-left: 250px;
  width: calc(100% - 250px);
}

/*
  For Mobile and Tablet
*/
@media (max-width: 992px) {
  .sidebar {
    left: 0px;
    z-index: 100;
    width: 230px;
    box-shadow: none;
    transition: width 0.3s;
    .corner {
      transition: all 0.3s;
      left: 230px;
    }
    &.hide {
      box-shadow: none;
      left: -230px;
      .corner {
        transition: all 0.3s;
        left: 0px;
      }
      + .view-container {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
  .view-container {
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }
}

.sidebar-new-dot {
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  left: 3.5rem;
  top: 50%;
  transform: translateY(-50%);
}
