.search-bar {
  border: 1px solid rgba(getColor("primary"), 0);
  border-radius: 0.5rem;
  outline: 0;
  margin-bottom: 0.5rem;
  @include shadow(0.25);
  &:hover,
  &:focus {
    @include shadow(0.4);
    border: 1px solid rgba(getColor("primary"), 0.44);
  }
  .form-control {
    box-shadow: none;
    &:focus {
      box-shadow: none;
      border: none;
    }
  }
  .input-group-text {
    background: getColor("white");
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: 0;
  }
}
