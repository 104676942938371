pagination {
  height: 36px;
  margin: 0;
  padding: 0;
}
.pager,
.pagination ul {
  margin-left: 0;
  *zoom: 1;
}
.pagination ul {
  padding: 0;
  display: inline-block;
  *display: inline;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.pagination li {
  display: inline;
}
.pagination a {
  float: left;
  padding: 0 12px;
  line-height: 30px;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
}
.pagination .active a,
.pagination a:hover {
  background-color: #f5f5f5;
  color: #94999e;
}
.pagination .active a {
  color: #94999e;
  cursor: default;
}
.pagination .disabled a,
.pagination .disabled a:hover,
.pagination .disabled span {
  color: #94999e;
  background-color: transparent;
  cursor: default;
}
.pagination li:first-child a,
.pagination li:first-child span {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.pagination li:last-child a {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.pagination-centered {
  text-align: center;
}
.pagination-right {
  text-align: right;
}
.pager {
  margin-bottom: 18px;
  text-align: center;
}
.pager:after,
.pager:before {
  display: table;
  content: "";
}
.pager li {
  display: inline;
}
.pager a {
  display: inline-block;
  padding: 5px 12px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.pager a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}
.pager .next a {
  float: right;
}
.pager .previous a {
  float: left;
}
.pager .disabled a,
.pager .disabled a:hover {
  color: #999;
  background-color: #fff;
  cursor: default;
}
.pagination .prev.disabled span {
  float: left;
  padding: 0 12px;
  line-height: 30px;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 1;
}
.pagination .next.disabled span {
  float: left;
  padding: 0 12px;
  line-height: 30px;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
}
.pagination li.active,
.pagination li.disabled {
  float: left;
  line-height: 30px;
  text-decoration: none;
  border-left-width: 0;
}
.pagination li.active {
  background: #2b60fa;
  color: #fff;
  padding: 0;
  a {
    background-color: #2b60fa;
    color: #fff;
    border: none;
  }
}
.pagination li:first-child {
  border-left-width: 1px;
}
