.form-progress {
  position: relative;
  width: 100%;
  height: 80px;

  .check-icon {
    border: 0;
    outline: 0;
    background: transparent;
    font-weight: 700;
    text-align: center;
    position: absolute;
    z-index: 1;
    transform: translateX(-50%);
    p {
      white-space: nowrap;
    }

    &:nth-child(1) {
      left: 0%;
      transform: translateX(-19px);
    }
    &:nth-child(2) {
      left: 16.666666666666668%;
    }
    &:nth-child(3) {
      left: 33.333333333333336%;
    }
    &:nth-child(4) {
      left: 50%;
    }
    &:nth-child(5) {
      left: 66.66666666666667%;
    }
    &:nth-child(6) {
      left: 83.33333333333334%;
    }
    &:nth-child(7) {
      left: 100%;
      @media all and (max-width: 575px) {
        transform: translateX(-19px);
      }
      transform: translateX(-38px);
    }
    @media all and (max-width: 575px) {
      svg {
        height: 36px;
      }
    }
  }

  .progress {
    position: absolute;
    width: 100%;
    height: 0.2rem;
    top: 17px;
    z-index: 0;
  }
}

.fileUploader {
  .fileContainer {
    box-shadow: 0px 4px 10px #ededed;

    .chooseFileButton {
      background-color: map-get($theme-colors, "primary");
    }

    .deleteImage {
      font-size: 1rem;
    }
  }
}

.form-activity {
  label.form-label {
    color: #445380;
  }
}

.btn-transparent {
  border: 0;
  background: transparent;
  padding: 0.5rem;
  outline: 0px;
  box-shadow: none !important;
  &:hover,
  &:focus {
    outline: 0px;
    opacity: 0.7;
  }
  &::after {
    display: none;
  }
}

.preview {
  background-color: map-get($theme-colors, "primary");
  padding: 1.25rem;
  border-radius: 1.25rem;
  > .card-body {
    background-color: #f9f9f9;
    border-radius: 1.25rem;
    padding: 1.25rem;

    .card {
      border-radius: 1.25rem;
      border: 0;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    }
    .carousel-item {
      img {
        object-fit: cover;
        height: 250px;
      }
    }
  }
}

.google-places {
  position: relative;
  .list-group {
    position: absolute;
    width: 100%;
    z-index: 99;
    .list-group-item {
      margin-bottom: 0;
      border-radius: 0px;
      &:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
      }
      &:last-child {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    }
  }
}

.edit-image-container {
  position: relative;
  img {
    height: 250px;
    width: 100%;
    object-fit: cover;
  }
  &.more-images {
    img {
      height: 150px;
    }
  }
  .btn-image-delete {
    position: absolute;
    bottom: 8px;
    right: 8px;
    box-shadow: none;
  }
}

.btn-image-upload {
  span.btn.btn-primary {
    box-shadow: none;
  }
  input {
    display: none;
  }
  &.edit {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.image-preview-container {
  img {
    height: 150px;
    width: 100%;
    object-fit: cover;
  }
}

.doc-input {
  margin-bottom: 0px;
  cursor: pointer;
  input {
    display: none;
  }
  color: #fff;
}

.activity-form-navigation-button {
  @media all and (max-width: 768px) {
    font-size: 10px !important;
    font-weight: bold;
  }
  @media all and (max-width: 320px) {
    font-size: 6px !important;
    font-weight: bold;
  }
}
.form-control-search {
  max-width: 540px;
}

.btn-wide-primary {
  color: map-get($theme-colors, "primary");
  background-color: rgba($color: map-get($theme-colors, "primary"), $alpha: 0.2);
  padding: 0.75rem 1.125rem;
  border-radius: 5px;
  transition: all 0.3s;
  font-weight: 600;
  &:hover,
  &:focus {
    outline: 0;
    background-color: rgba($color: map-get($theme-colors, "primary"), $alpha: 0.3);
  }
}
