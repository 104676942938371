.signup {
  .slider {
    margin-top: 4rem;
    .carousel-indicators {
      margin-bottom: 0.5rem;
      @include marginX(0rem);
      justify-content: flex-start;
      li {
        background-color: #bbbbbb;
        height: 6px;
        width: 6px;
        border-radius: 3px;
        border: none;
        transition: all 0.5;
        &.active {
          border-radius: 3px;
          width: 19px;
          background-color: getColor("success");
        }
      }
    }
    .carousel-inner {
      padding-bottom: 22px;
    }
    h4 {
      color: #3c58ac;
    }
    p {
      color: #445380;
    }
  }
}

.search-result {
  height: 300px;
  overflow-y: auto;
}
.search-result-item {
  font-family: $font-family-muli;
  .custom-control-label:before {
    background-color: getColor("white");
    border-radius: 0.5rem;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    border-color: getColor("success");
    background-color: getColor("success");
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
  > img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 1rem;
  }
  h6 {
    color: #3c58ac;
    font-weight: 700;
    margin-bottom: 0rem;
  }
  p {
    margin-bottom: 0px;
    color: #676767;
    word-wrap: normal;
  }
  p,
  span {
    font-size: getFontSize("sm");
  }
  .reviews {
    color: #494949;
  }
  .border-right {
    border-right: 2px solid getColor("grey-light-4") !important;
  }
}

.image-form {
  .image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  .more {
    position: relative;
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      color: getColor("white");
    }
    img {
      filter: brightness(70%);
    }
  }
  .add {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    .btn {
      border-radius: 0.5rem;
      cursor: pointer;
      width: 100px;
      height: 100px;
      font-size: 0.8rem;
      &:hover {
        background-color: getColor("grey-light-4");
      }
    }
  }
  .form-label {
    font-weight: 500;
    color: getColor("grey-light-2");
  }
}

@media (min-width: 768px) {
  .signup {
    .slider {
      margin-top: 16rem;
    }
  }
}

@media (min-width: 992px) {
  .signup {
    .slider {
      margin-top: 16rem;
    }
  }
}

.not-verified {
  min-height: calc(100vh - 21px);
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
