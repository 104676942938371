@mixin paddingX($value) {
  padding-left: $value;
  padding-right: $value;
}
@mixin paddingY($value) {
  padding-top: $value;
  padding-bottom: $value;
}
@mixin marginX($value) {
  margin-left: $value;
  margin-right: $value;
}
@mixin marginY($value) {
  margin-top: $value;
  margin-bottom: $value;
}
@mixin border($radius) {
  border: 1.2px solid getColor("grey-light-4");
  border-radius: $radius;
}
@mixin shadow($alpha) {
  box-shadow: 0px 4px 20px rgba(#9b9b9b, $alpha);
}
@mixin centerAbsolute {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
