@use "sass:map";

$theme-colors: (
  "primary": #2c60fa,
  "danger": #f94343,
  "success": #3cdb8f,
  "warning": #ffc107,
);

$colors: (
  "primary": #2c60fa,
  "primary-dark": #56648e,
  "danger": #f94343,
  "danger-light": #ff6464,
  "success": #3cdb8f,
  "black": #000,
  "white": #fff,
  "grey-light-1": #56648e,
  "grey-light-2": #445380,
  "grey-light-3": #7e8bb5,
  "grey-light-4": #f3f3f3,
  "grey": #bdbdbd,
  "warning": #ffc107,
);

@function getColor($key) {
  @if not map-has-key($colors, $key) {
    @return map-get($colors, "primary");
  }
  @return map-get($colors, $key);
}
.text-primary-dark {
  color: getColor("primary-dark") !important;
}
.text-grey {
  color: getColor("grey") !important;
}
.text-grey-light {
  color: getColor("grey-light-3") !important;
}

.bg-primary-light {
  background-color: #f4f7ff !important;
}

.bg-primary-lighter {
  background-color: #e3ebff !important;
}

.badge-warning-light {
  padding: 0.5rem 1.5rem !important;
  color: black;
  background-color: rgba($color: getColor("warning"), $alpha: 0.25);
}

.badge-success-light {
  color: black;
  background-color: rgba($color: getColor("success"), $alpha: 0.25);
  padding: 0.5rem 1.5rem !important;
}
.badge-primary-light {
  color: black;
  background-color: rgba($color: getColor("primary"), $alpha: 0.25);
  padding: 0.5rem 1.5rem !important;
}
.badge-danger-light {
  color: black;
  background-color: rgba($color: getColor("danger"), $alpha: 0.25);
  padding: 0.5rem 1.5rem !important;
}
