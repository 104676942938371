.payment-status {
  background-color: getColor("primary");
  background: linear-gradient(130deg, #2955fb 0%, #29a3fb 100%);
  min-height: 100vh;
  position: relative;
  .circles {
    .circle {
      position: fixed;
      background: #2955fb;
      border-radius: 50%;
      &:nth-child(1) {
        width: 20vh;
        height: 20vh;
        left: 126px;
        top: -41px;
      }
      &:nth-child(2) {
        width: 473px;
        height: 473px;
        left: 1004px;
        top: -143px;
      }
      &:nth-child(3) {
        width: 158px;
        height: 158px;
        left: 713px;
        top: 201px;
      }
      &:nth-child(4) {
        width: 158px;
        height: 158px;
        left: 429px;
        top: 436px;
      }
      &:nth-child(5) {
        width: 158px;
        width: 322px;
        height: 322px;
        left: 1112px;
        top: 617px;
      }
    }
  }
  .icon {
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin-top: -2.5rem - 1.25rem;
    border: 0.5rem solid getColor("white");
    img {
      position: absolute;
      @include centerAbsolute;
    }
  }
  .card {
    position: absolute;
    border-radius: 2rem;
    max-width: 400px;
    width: 90%;
    @include centerAbsolute;
  }
}

.payment-details {
  .circle {
    width: 125px;
    height: 125px;
    background: #f1f4ff;
    @include marginX(auto);
    border-radius: 50%;
  }
}

.payment-method {
  .custom-control-label:before {
    background-color: getColor("white");
    border-radius: 0.5rem;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    border-color: getColor("success");
    background-color: getColor("success");
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      border-bottom: 1px solid #ebebeb;
      &:last-child {
        border: none;
      }
      label {
        cursor: pointer;
      }
    }
  }
}

.payment-forms {
  label {
    color: #3b4773;
    font-size: 16px;
    font-weight: 600;
  }
}

.card-details-form {
  background: #f1f4fe;
  label {
    color: #4d5262;
    font-size: 0.8rem;
  }
  input,
  input::placeholder {
    font-size: 0.8rem;
  }
}

.no-payment-details {
  font-size: 16px;
}
