.chat-header {
  img {
    width: 50px;
    height: 50px;
    border-radius: 0.5rem;
  }
}

.chat-time {
  color: #c1c1c1;
  font-size: 0.7rem;
}

.chat-bubble {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &.self {
    align-items: flex-end;
    text-align: right;
  }
}
.chat-messages {
  height: calc(100vh - 60px - 1.5rem - 75px - 9rem);
  overflow-y: auto;
}
.chat-message {
  background-color: getColor("primary");
  padding: 1rem;
  border-radius: 0.3rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  max-width: 80%;
  p {
    color: getColor("white");
    margin-bottom: 0;
  }
  &.self {
    background-color: #3cdb8f;
    text-align: right;
    border-radius: 1rem 0.3rem 1rem 1rem;

    p {
      color: #000;
    }
  }

  svg {
    font-size: 0.5rem;
  }
}

.is-typing {
  color: #9ab5ff;
}

.chat-input-group {
  border: 2px solid #ededed;
  border-radius: 20px;
  -moz-box-shadow: 2px 2px 2px 2px #ededed;
  -webkit-box-shadow: 2px 2px 2px 2px #ededed;
  box-shadow: 2px 2px 2px 2px #ededed;
  padding: 0.2rem;
  display: flex;
  margin-top: 1rem;
  .btn-emoji {
    border: 0;
    background-color: transparent;
    padding: 0 1rem;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .btn-send {
    border: 0;
    background-color: transparent;
    padding: 0;
    outline: 0;
    img {
      width: 24px;
      height: 24px;
    }
  }
  input {
    flex-grow: 1;
    border: 0;
    outline: 0;
    &::placeholder {
      color: #bababa;
      font-family: $font-family-muli;
    }
  }
}
.inbox {
  max-height: 80vh;
  overflow-y: auto;
}

.chat-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-container {
    height: 60px;
    width: 60px;
  }
}
