.popover {
  border: 0px;
  z-index: 100;
  .popover-body {
    background: #f0f4ff;
    padding: 1rem;
    border-radius: 0.3rem;
  }
  .arrow {
    &::before,
    &::after {
      border-bottom-color: #f0f4ff !important;
    }
  }
}
.tooltip {
  // .arrow {
  //   &::before,
  //   &::after {
  //     // border-bottom-color: #f0f4ff !important;
  //   }
  // }
  .tooltip-inner {
    color: getColor("dark");
    background: #f0f4ff;
    padding: 1rem;
    border-radius: 0.3rem;
  }
}

#button-tooltip {
  .arrow {
    &::before,
    &::after {
      border-right-color: #2c60fa;
    }
  }
}

#payment-info-tooltip {
  .arrow {
    &::before,
    &::after {
      border-right-color: #f0f4ff;
    }
  }
}
